<template>
  <div class="card">
      <h3>{{test.name}}</h3>
      <h5 class="price">Price: ${{test.price}}</h5>
      <p class="description">{{description}}</p>
      <h4 class="test-detail-button" @click="showDetails">Learn More</h4>
      <button v-if="!test.isAdd" @click="addItem">
            Add to cart
      </button>
      <button class="text-muted" v-if="test.isAdd" @click="removeItem">
            Remove from cart
      </button>
  </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex';

export default {
  props: ['test'],
  computed: {
    ...mapGetters([ 'transactionID' ]),
    description() {
            return this.test.description.substring(0,100) + '...'
    }
  },
  methods: {
    ...mapActions(['addTestToCart','removeTestInCart', 'assignCartToPatient']),
    addItem() {
      this.addTestToCart(this.test)
    },
    removeItem() {
			this.removeTestInCart(this.test)
		},
    showDetails() {
      this.$router.push('/dat/product/' + this.test.testId)
    }
  },
}
</script>

<style lang="scss">
h3 {
  text-align: center;
  color: black;
}
    .card{
        width: 80%;
        margin: 10%;
        padding: 10px;
        border-radius: 5px;
        background-color: white;
        box-shadow: 0 0 5px grey;

        h5.price{
            color: grey;
        }

        p.description {
            font-size: .85rem;
            padding: 10px;
        }
        button {
           width: 250px;
           padding-bottom: 10px;
           margin-top: 29px;
        }
       
        button.text-muted {
            color: white;
            background-color: grey;
        }
        
    }
    h4.test-detail-button {
            padding: 10px;
            color: rgb(46, 126, 153);
            font-weight: bold;
            font-size: 1.15rem;    
            cursor: pointer;
        }

    @media (min-width: 500px) {
        .card {
            width: 350px;
            margin: 10px;
        }
    }

</style>